import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useProblemEditableStore } from '@features/backoffice/problems-import/ProblemEditableStore';
import { type ModelParams } from '@features/backoffice/problems-import/utils/constructEditedProblemsImportCandidate';
import { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

function useProblemCandidateDropTarget(props: ModelParams) {
	const ref = useRef(null);

	const candidateImages = useProblemEditableStore((state) => state.candidateImages);
	const isMappingImagesEnabled = useProblemEditableStore((state) => state.isMappingImagesEnabled);
	const onEditChange = useProblemEditableStore((state) => state.onEditChange);
	const [isDraggedOver, setIsDraggedOver] = useState(false);

	useEffect(() => {
		if (!isMappingImagesEnabled || !onEditChange) return;

		const el = ref.current;
		invariant(el);

		return dropTargetForElements({
			element: el,
			onDragEnter: () => setIsDraggedOver(true),
			onDragLeave: () => setIsDraggedOver(false),
			onDrop: (item) => {
				onEditChange({
					modelparams: props,
					fieldname: 'image_ids',
					// @ts-expect-error - TODO: fix this
					newFieldValue: [item.source.data.candidateImage?.image?.id as number],
					isEditingComparisonData: false,
					// @ts-expect-error - TODO: fix this
					candidateImageId: item.source.data.candidateImage?.id as number,
				});
				setIsDraggedOver(false);
			},
		});
	}, []);

	return {
		ref,
		isDraggedOver,
		candidateImages,
	};
}

export default useProblemCandidateDropTarget;
