'use client';

import type { Question } from '@features/api/client';
import QuestionAnswerSelect from './QuestionAnswerSelect';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';
import EditableText from '../Problem/EditableText';
import { Tooltip } from 'antd';
import { Fragment } from 'react';

type QuestionAutocompleteProps = {
	questions: Array<Question>;
	leadText?: string | null | undefined;
};

function QuestionAutocomplete({ questions, leadText }: QuestionAutocompleteProps) {
	return (
		<div className="prose !max-w-[75ch] [&_.question-text]:!inline [&_.question-text_p]:!inline">
			<EditableProblemRichtext
				text={leadText ? leadText + ' ' : ''}
				className="prose"
				fieldname="lead_text"
				modelparams={{ type: 'problem' }}
			/>
			{questions.map((question, questionIndex) => {
				return (
					<Fragment key={question.id}>
						<EditableProblemRichtext
							text={question.text}
							fieldname="text"
							modelparams={{ type: 'question', questionIndex: questionIndex }}
						/>
						<QuestionAnswerSelect
							question={question}
							uniqueAnswers={question.answers}
							initialAnswers={question.answers}
						/>
						<EditableText
							fieldname="points"
							modelparams={{ type: 'question', questionIndex }}
							text={question.points}
							className="relative ml-1 mr-2 inline rounded-[2px] bg-green-200 px-2 py-1 text-xs font-bold"
							data-tooltip="Broj bodova"
						/>
						<EditableProblemRichtext
							text={question.postanswer_text ? question.postanswer_text + ' ' : ''}
							fieldname="postanswer_text"
							modelparams={{ type: 'question', questionIndex: questionIndex }}
						/>
						{question.question_display_type === 'vertical' && (
							<>
								<br />
								<br />
							</>
						)}
					</Fragment>
				);
			})}
		</div>
	);
}

export default QuestionAutocomplete;
