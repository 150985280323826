'use client';

import { useEffect } from 'react';
import { renderMathInElement } from '../utils/katextAutoRender';

export function katexTypeset(DOMelement?: HTMLElement | null) {
	renderMathInElement(DOMelement || document.body);
}

export function useKatexTypeset(DOMelement?: HTMLElement | null) {
	useEffect(() => {
		if (DOMelement === null) return;
		if (window === undefined) return;

		katexTypeset(DOMelement);
		// @ts-ignore
		window.katexTypeset = katexTypeset;
	});
}

export function KatexProvider({
	children,
	element = undefined,
}: {
	children: React.ReactNode;
	element?: HTMLElement | null;
}) {
	useKatexTypeset(element);
	return children;
}
