import type { AnswerTypeEnum, QuestionAnswerBlock } from '@features/api/client';
import { useKatexTypeset } from '@features/shared/providers/KatexProvider';
import AnswerChoiceBlocks from './AnswerChoiceBlocks';
import AnswerTextBlocks from './AnswerTextBlocks';

type QuestionAnswerBlocksProps = {
	blocks: Array<QuestionAnswerBlock>;
	type?: AnswerTypeEnum;
};

function QuestionAnswerBlocks({ blocks, type }: QuestionAnswerBlocksProps) {
	useKatexTypeset();

	switch (type) {
		case 'answer_choice':
			return <AnswerChoiceBlocks blocks={blocks} key={type} />;
		case 'answer_text':
			return <AnswerTextBlocks blocks={blocks} key={type} />;
		default:
			return null;
	}
}

export default QuestionAnswerBlocks;
