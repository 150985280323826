'use client';

import useProblemCandidateDropTarget from '@features/backoffice/problems-import/hooks/useProblemCandidateDropTarget';
import ProblemCandidateImage from '@features/backoffice/problems-import/ProblemCandidateImage';
import EditableProblemRichtext from '@features/frontoffice/problems/ui/Problem/EditableProblemRichtext';
import { cn } from '@features/shared/utils/utils';
import React from 'react';

function ProblemLeadText({
	leadText,
	problemCandidateImageIds,
}: {
	leadText: string | null | undefined;
	problemCandidateImageIds?: number[];
}) {
	const editModelParams = {
		type: 'problem',
	} as const;
	const { ref, isDraggedOver, candidateImages } = useProblemCandidateDropTarget(editModelParams);

	const images =
		candidateImages?.filter((image) => problemCandidateImageIds?.includes(image.image.id)) ?? [];

	return (
		<div
			className={cn(
				'relative flex flex-col gap-4',
				isDraggedOver &&
					'before:absolute before:bottom-[calc(100%_+_2px)] before:left-1 before:rounded-t-sm before:bg-purple-600 before:p-1 before:text-xs before:font-bold before:text-white before:content-["Problem"] after:absolute after:inset-[-2px] after:z-10 after:rounded-sm after:border after:border-dashed after:border-purple-600 after:bg-purple-600/10'
			)}
			ref={ref}>
			<EditableProblemRichtext
				text={leadText ? leadText + ' ' : ''}
				className="prose"
				fieldname="lead_text"
				modelparams={editModelParams}
			/>
			{images.map((candidateImage) => (
				<ProblemCandidateImage
					key={candidateImage.id}
					image={candidateImage.image}
					candidateImageId={candidateImage.id}
					modelparams={editModelParams}
				/>
			))}
		</div>
	);
}

export default ProblemLeadText;
