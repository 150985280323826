import type { TypedTableBlockValue } from '@features/api/client';
import { richtext } from '@features/shared/utils/richtext';
import { cn } from '../utils/utils';

type TableBlockProps = {
	block: TypedTableBlockValue;
};

function TableBlock({ block }: TableBlockProps) {
	const shouldShowHeader = block.columns.some((column) => column.heading);
	const columns = shouldShowHeader ? block.columns : [];

	return (
		<div className="overflow-x-auto">
			<div
				className="grid rounded-[6px] border border-b-0 border-r-0 border-solid border-gray-150"
				style={{
					gridTemplateColumns: `repeat(${block.columns.length}, minmax(140px, 1fr))`,
				}}>
				{columns.map((column, index) => (
					<div
						className="border-b border-r border-solid border-gray-150 bg-gray-100 p-3"
						style={{
							borderTopLeftRadius: index === 0 ? '6px' : 'none',
							borderTopRightRadius: index === block.columns.length - 1 ? '6px' : 'none',
						}}
						key={column.heading}>
						{column.heading}
					</div>
				))}
				{block.rows.map((row, index) =>
					row.values.map((value, i) => (
						<div
							className={cn(
								'border-b border-r border-solid border-gray-150 p-3',
								index === block.rows.length - 1 && i === 0 && 'rounded-bl-[6px]',
								index === block.rows.length - 1 &&
									i === row.values.length - 1 &&
									'rounded-br-[6px]',
								!shouldShowHeader && index === 0 && i === 0 && 'rounded-tl-[6px]',
								!shouldShowHeader &&
									index === 0 &&
									i === row.values.length - 1 &&
									'rounded-tr-[6px]'
							)}
							key={`row-value-${i}`}>
							{richtext(value, 'prose')}
						</div>
					))
				)}
			</div>
		</div>
	);
}

export default TableBlock;
