import React from 'react';

import { Divider } from 'antd';
import { ScissorOutlined } from '@ant-design/icons';
import useSplitProblemCandidate from '@features/backoffice/problems-import/api/client/useSplitProblemCandidate';
import { useProblemEditableStore } from '@features/backoffice/problems-import/ProblemEditableStore';

type QuestionSplitDividerProps = {
	questionIndex: number;
	problemsImportCandidateId: number;
	problemSourceId: number;
};

function QuestionSplitDivider({
	questionIndex,
	problemSourceId,
	problemsImportCandidateId,
}: QuestionSplitDividerProps) {
	const isProblemEditable = useProblemEditableStore((state) => state.isProblemEditable);
	const { mutate: splitProblemCandidate } = useSplitProblemCandidate(problemSourceId);

	if (!isProblemEditable) return null;

	return (
		<div onClick={() => splitProblemCandidate({ questionIndex, problemsImportCandidateId })}>
			<Divider dashed className="group cursor-pointer">
				<ScissorOutlined className="group-hover:text-primary-red" />
			</Divider>
		</div>
	);
}

export default QuestionSplitDivider;
