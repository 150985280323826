import { useEffect, useRef, useState } from 'react';
import Text from 'antd/es/typography/Text';
import { useProblemEditableStore } from '@features/backoffice/problems-import/ProblemEditableStore';
import type { EditProblemCandidateParams } from '@features/backoffice/problems-import/utils/constructEditedProblemsImportCandidate';
import { KatexProvider } from '@features/shared/providers/KatexProvider';
import { apiClient } from '@features/api/api.client';

export type EditableProblemTextProps = {
	text: string | number | null | undefined;
	className?: string;
} & Omit<
	EditProblemCandidateParams,
	'newFieldValue' | 'isEditingComparisonData' | 'problemsImportCandidateId'
>;

function EditableText({ text, className, ...rest }: EditableProblemTextProps) {
	const editableTextRef = useRef<HTMLSpanElement>(null);
	const [editedText, setEditedText] = useState(text);

	const isProblemEditable = useProblemEditableStore((state) => state.isProblemEditable);
	const isShowingComparisonData = useProblemEditableStore((state) => state.isShowingComparisonData);
	const onEditChange = useProblemEditableStore((state) => state.onEditChange);
	const problemsImportCandidateId = useProblemEditableStore((state) => state.problemsImportCandidateId);

	const query = apiClient.problemsImport.useGetProblemsImportCandidate({
		problemsImportCandidateId,
		enabled: !!problemsImportCandidateId && isProblemEditable,
	});

	const problemsImportCandidate = query?.data;

	const onChange = (value: string) => {
		if (!isProblemEditable) return;

		setEditedText(value);
		if (onEditChange) {
			const newFieldValue = typeof text === 'number' ? parseInt(value) : value;
			onEditChange({
				newFieldValue,
				isEditingComparisonData:
					!!isShowingComparisonData && !!problemsImportCandidate?.comparison_problem_data,
				...rest,
			});
		}
	};

	useEffect(() => {
		setEditedText(text);
	}, [text]);

	if (text === undefined || text === null) return null;
	if (isProblemEditable)
		return (
			<KatexProvider element={editableTextRef.current}>
				<Text
					ref={editableTextRef}
					editable={{
						tooltip: 'click to edit text',
						onChange,
						triggerType: ['text', 'icon'],
					}}
					className={className}>
					{editedText}
				</Text>
			</KatexProvider>
		);

	const strippedRest = { ...rest, fieldname: undefined, modelparams: undefined };

	return (
		<span className={className} {...strippedRest}>
			{text}
		</span>
	);
}

export default EditableText;
