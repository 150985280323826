'use client';

import type {
	ProblemsImportCandidateQuestion,
	Question,
	QuestionBlock,
	ProblemsImportCandidateImage,
} from '@features/api/client';
import ProblemContentBlock from '@features/shared/blocks/ProblemContentBlock';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';
import { cn } from '@features/shared/utils/utils';
import useProblemCandidateDropTarget from '@features/backoffice/problems-import/hooks/useProblemCandidateDropTarget';
import ProblemCandidateImage from '@features/backoffice/problems-import/ProblemCandidateImage';

type QuestionTextProps = {
	question: Question | ProblemsImportCandidateQuestion;
	questionIndex: number;
};

function QuestionText({ question, questionIndex }: QuestionTextProps) {
	const editModelParams = {
		type: 'question',
		questionIndex,
	} as const;
	const { ref, isDraggedOver, candidateImages } = useProblemCandidateDropTarget(editModelParams);

	let images: ProblemsImportCandidateImage[] = [];
	let blocks: QuestionBlock[] = [];

	if ('image_ids' in question) {
		images = candidateImages?.filter((image) => question.image_ids?.includes(image.image.id)) ?? [];
	}

	if ('blocks' in question) {
		blocks = question.blocks;
	}

	return (
		<div
			className={cn(
				'relative flex flex-col gap-4',
				isDraggedOver &&
					'before:absolute before:bottom-[calc(100%_+_2px)] before:left-1 before:rounded-t-sm before:bg-purple-600 before:p-1 before:text-xs before:font-bold before:text-white before:content-["Question"] after:absolute after:inset-[-2px] after:z-10 after:rounded-sm after:border after:border-dashed after:border-purple-600 after:bg-purple-600/10'
			)}
			ref={ref}>
			<EditableProblemRichtext
				text={question.text}
				className="prose"
				fieldname="text"
				modelparams={editModelParams}
			/>
			{blocks.map((block) => (
				<ProblemContentBlock block={block} key={block.id} />
			))}
			{images.map((candidateImage) => (
				<ProblemCandidateImage
					key={candidateImage.id}
					image={candidateImage.image}
					candidateImageId={candidateImage.id}
					modelparams={editModelParams}
				/>
			))}
		</div>
	);
}

export default QuestionText;
