'use client';

import { type QuestionGroup as QuestionGroupType } from '../../domain/normalizeQuestions';
import QuestionAutocompleteFromSamePool from './QuestionAutocompleteFromSamePool';
import { Flex } from 'antd';
import QuestionAutocomplete from './QuestionAutocomplete';
import QuestionSingleChoice from './QuestionSingleChoice';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionText from './QuestionText';
import QuestionSingleLine from './QuestionSingleLine';
import QuestionFillInTheBlank from './QuestionFillInTheBlank';
import { Fragment } from 'react';
import QuestionSplitDivider from '@features/backoffice/problems/ui/Question/QuestionSplitDivider';
import EditableText from '../Problem/EditableText';
import ProblemLeadText from '@features/frontoffice/problems/ui/Problem/ProblemLeadText';

type QuestionGroupProps = {
	questionGroup: QuestionGroupType;
	leadText?: string | null | undefined;
	problemSourceId?: number;
	problemsImportCandidateId?: number;
	problemCandidateImageIds?: number[];
};

function QuestionGroup({
	problemCandidateImageIds,
	questionGroup,
	leadText,
	problemsImportCandidateId,
	problemSourceId,
}: QuestionGroupProps) {
	const questionSplitMarkup = (questionIndex: number) => {
		if (!problemSourceId || !problemsImportCandidateId || questionIndex === 0) return null;

		return (
			<QuestionSplitDivider
				problemSourceId={problemSourceId}
				problemsImportCandidateId={problemsImportCandidateId}
				questionIndex={questionIndex}
			/>
		);
	};

	let questionsMarkup = null;
	switch (questionGroup.type) {
		case 'autocomplete_from_same_pool':
			questionsMarkup = (
				<QuestionAutocompleteFromSamePool questions={questionGroup.questions} leadText={leadText} />
			);
			break;
		case 'autocomplete':
			questionsMarkup = (
				<QuestionAutocomplete questions={questionGroup.questions} leadText={leadText} />
			);
			break;

		case 'single_choice':
			questionsMarkup = (
				<Flex gap={40} vertical>
					<ProblemLeadText
						leadText={leadText}
						problemCandidateImageIds={problemCandidateImageIds}
					/>
					{questionGroup.questions.map((question, questionIndex) => (
						<Fragment key={question.id}>
							{questionSplitMarkup(questionIndex)}
							<div className="flex flex-col gap-4">
								<QuestionText question={question} questionIndex={questionIndex} />
								<QuestionSingleChoice
									answerChoices={question.answers}
									questionId={question.id}
									questionIndex={questionIndex}
								/>
								<EditableText
									fieldname="points"
									modelparams={{ type: 'question', questionIndex }}
									text={question.points}
									className="relative ml-auto flex h-6 w-6 items-center justify-center rounded-[2px] bg-green-200 text-sm font-bold"
									data-tooltip="Broj bodova"
								/>
							</div>
						</Fragment>
					))}
				</Flex>
			);
			break;
		case 'multiple_choice':
			questionsMarkup = (
				<Flex gap={40} vertical>
					<ProblemLeadText
						leadText={leadText}
						problemCandidateImageIds={problemCandidateImageIds}
					/>
					{questionGroup.questions.map((question, questionIndex) => (
						<Fragment key={question.id}>
							{questionSplitMarkup(questionIndex)}
							<div className="flex flex-col gap-4">
								<QuestionText question={question} questionIndex={questionIndex} />
								<QuestionMultipleChoice
									answerChoices={question.answers}
									questionId={question.id}
									questionIndex={questionIndex}
								/>
								<EditableText
									fieldname="points"
									modelparams={{ type: 'question', questionIndex }}
									text={question.points}
									className="relative ml-auto flex h-6 w-6 items-center justify-center rounded-[2px] bg-green-200 text-sm font-bold"
									data-tooltip="Broj bodova"
								/>
							</div>
						</Fragment>
					))}
				</Flex>
			);
			break;

		case 'simple_text':
			questionsMarkup = (
				<Flex gap={40} vertical>
					<ProblemLeadText
						leadText={leadText}
						problemCandidateImageIds={problemCandidateImageIds}
					/>
					{questionGroup.questions.map((question, questionIndex) => (
						<Fragment key={question.id}>
							{questionSplitMarkup(questionIndex)}
							<div className="flex flex-col gap-4">
								<QuestionText question={question} questionIndex={questionIndex} />
								<QuestionSingleLine
									textAnswers={question.answers}
									questionIndex={questionIndex}
								/>
								<EditableText
									fieldname="points"
									modelparams={{ type: 'question', questionIndex }}
									text={question.points}
									className="relative ml-auto flex h-6 w-6 items-center justify-center rounded-[2px] bg-green-200 text-sm font-bold"
									data-tooltip="Broj bodova"
								/>
							</div>
						</Fragment>
					))}
				</Flex>
			);
			break;

		case 'fill_in_the_blank':
			questionsMarkup = (
				<QuestionFillInTheBlank questions={questionGroup.questions} leadText={leadText} />
			);
			break;
		default:
			break;
	}

	return questionsMarkup;
}

export default QuestionGroup;
