import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import QuestionAnswerChoice from '@features/frontoffice/problems/ui/QuestionAnswer/QuestionAnswerChoice';
import { Flex, Radio } from 'antd';
import { useQuestionGroupStore } from './QuestionGroupStore';

type QuestionSingleChoiceProps = {
	answerChoices: Array<QuestionAnswerType>;
	questionId: number;
	questionIndex: number;
};

function QuestionSingleChoice({ answerChoices, questionId, questionIndex }: QuestionSingleChoiceProps) {
	const selectedAnswerChoices = useQuestionGroupStore((state) => state.selectedAnswerChoices);
	const setSelectedAnswerChoices = useQuestionGroupStore((state) => state.setSelectedAnswerChoices);
	const isChangingAnswersDisabled = useQuestionGroupStore((state) => state.isChangingAnswersDisabled);
	const areCorrectAnswersBeingShown = useQuestionGroupStore((state) => state.areCorrectAnswersBeingShown);

	const selectedAnswerChoiceId = selectedAnswerChoices.find(
		(choice) => choice.questionId === questionId
	)?.answerId;
	const correctAnswerChoiceId = answerChoices.find((answer) => answer.is_correct)?.id;

	return (
		<Radio.Group
			size="large"
			defaultValue={areCorrectAnswersBeingShown ? correctAnswerChoiceId : selectedAnswerChoiceId}
			value={areCorrectAnswersBeingShown ? correctAnswerChoiceId : selectedAnswerChoiceId}
			onChange={(e) => {
				if (isChangingAnswersDisabled) return;

				const selectedAnswerChoiceOptions = [
					...selectedAnswerChoices.filter((choice) => choice.questionId !== questionId),
					{ questionId: questionId, answerId: e.target.value },
				];

				setSelectedAnswerChoices(selectedAnswerChoiceOptions);
			}}>
			<Flex gap={16} vertical>
				{answerChoices.map((answer, index) => (
					<QuestionAnswerChoice
						key={answer.id}
						answer={answer}
						answerIndex={index}
						questionIndex={questionIndex}
					/>
				))}
			</Flex>
		</Radio.Group>
	);
}

export default QuestionSingleChoice;
