'use client';

import type {
	ProblemsImportCandidateImage,
	ProblemsImportCandidateQuestionAnswer,
	QuestionAnswerBlock,
	QuestionAnswer as QuestionAnswerType,
} from '@features/api/client';
import { useKatexTypeset } from '@features/shared/providers/KatexProvider';
import { Flex } from 'antd';
import { useRef } from 'react';
import QuestionAnswerBlocks from './QuestionAnswerBlocks';
import { choiceLabels } from '@features/backoffice/problems/ui/QuestionAnswer/choiceLabels';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';
import EditableText from '../Problem/EditableText';
import useProblemCandidateDropTarget from '@features/backoffice/problems-import/hooks/useProblemCandidateDropTarget';
import { cn } from '@features/shared/utils/utils';
import ProblemCandidateImage from '@features/backoffice/problems-import/ProblemCandidateImage';

type QuestionAnswerProps = {
	answer: QuestionAnswerType | ProblemsImportCandidateQuestionAnswer;
	answerIndex: number;
	questionIndex: number;
};

function QuestionAnswer({ answer, answerIndex, questionIndex }: QuestionAnswerProps) {
	const editModelParams = {
		type: 'question_answer',
		answerIndex,
		questionIndex,
	} as const;
	const { ref, isDraggedOver, candidateImages } = useProblemCandidateDropTarget(editModelParams);
	const mathjaxTypesetRef = useRef<HTMLDivElement>(null);
	useKatexTypeset(mathjaxTypesetRef.current);

	let images: ProblemsImportCandidateImage[] = [];
	let blocks: QuestionAnswerBlock[] = [];

	if ('image_ids' in answer) {
		images = candidateImages?.filter((image) => answer.image_ids?.includes(image.image.id)) ?? [];
	}

	if ('blocks' in answer) {
		blocks = answer.blocks;
	}

	return (
		<div ref={mathjaxTypesetRef}>
			<div
				className={cn(
					'relative flex w-full items-center gap-4',
					isDraggedOver &&
						'before:absolute before:bottom-[calc(100%_+_2px)] before:left-1 before:rounded-t-sm before:bg-purple-600 before:p-1 before:text-xs before:font-bold before:text-white before:content-["Answer"] after:absolute after:inset-[-2px] after:z-10 after:rounded-sm after:border after:border-dashed after:border-purple-600 after:bg-purple-600/10'
				)}
				ref={ref}>
				{answer.answer_type === 'answer_choice' && choiceLabels[answerIndex]}
				<Flex gap={4} align="center" wrap="wrap">
					<EditableText
						text={answer.display_prefix}
						fieldname="display_prefix"
						modelparams={editModelParams}
					/>
					<EditableProblemRichtext
						text={answer.display_text}
						fieldname="display_text"
						modelparams={editModelParams}
					/>
					<EditableText
						text={answer.display_sufix}
						fieldname="display_sufix"
						modelparams={editModelParams}
					/>

					<QuestionAnswerBlocks blocks={blocks} type={answer.answer_type} />
					{images.map((candidateImage) => (
						<ProblemCandidateImage
							key={candidateImage.id}
							image={candidateImage.image}
							candidateImageId={candidateImage.id}
							modelparams={editModelParams}
						/>
					))}
				</Flex>
			</div>
		</div>
	);
}

export default QuestionAnswer;
