import katex from 'katex';
import { mergeDeep } from 'remeda';

type Delimiter = { left: string; right: string; display: boolean };

type Options = {
	delimiters: Delimiter[];
	displayMode: boolean;
	errorCallback: (message: string, err: Error) => void;
	macros: Record<string, string>;
	ignoredTags: string[];
	ignoredClasses: string[];
	preProcess: (text: string) => string;
};

function findEndOfMath(delimiter: string, text: string, startIndex: number) {
	// Adapted from
	// https://github.com/Khan/perseus/blob/master/src/perseus-markdown.jsx
	var index = startIndex;
	var braceLevel = 0;
	var delimLength = delimiter.length;

	while (index < text.length) {
		var character = text[index];

		if (braceLevel <= 0 && text.slice(index, index + delimLength) === delimiter) {
			return index;
		} else if (character === '\\') {
			index++;
		} else if (character === '{') {
			braceLevel++;
		} else if (character === '}') {
			braceLevel--;
		}

		index++;
	}

	return -1;
}

function escapeRegex(text: string) {
	return text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

var amsRegex = /^\\begin{/;

function splitAtDelimiters(text: string, delimiters: Delimiter[]) {
	var index;
	var data = [];
	var regexLeft = new RegExp('(' + delimiters.map((x) => escapeRegex(x.left)).join('|') + ')');

	while (true) {
		index = text.search(regexLeft);

		if (index === -1) {
			break;
		}

		if (index > 0) {
			data.push({
				type: 'text',
				data: text.slice(0, index),
			});
			text = text.slice(index); // now text starts with delimiter
		} // ... so this always succeeds:

		var i = delimiters.findIndex((delim) => text.startsWith(delim.left));
		const delimiter = delimiters[i];

		if (!delimiter) continue;
		index = findEndOfMath(delimiter.right, text, delimiter.left.length);

		if (index === -1) {
			break;
		}

		var rawData = text.slice(0, index + delimiter.right.length);
		var math = amsRegex.test(rawData) ? rawData : text.slice(delimiter.left.length, index);
		data.push({
			type: 'math',
			data: math,
			rawData,
			display: delimiter.display,
		});
		text = text.slice(index + delimiter.right.length);
	}

	if (text !== '') {
		data.push({
			type: 'text',
			data: text,
		});
	}

	return data;
}

function renderMathInText(text: string, options: Options) {
	const data = splitAtDelimiters(text, options.delimiters);

	const firstData = data[0];

	if (data.length === 1 && firstData?.type === 'text') {
		// There is no formula in the text.
		// Let's return null which means there is no need to replace
		// the current text node with a new one.
		return null;
	}

	var fragment = document.createDocumentFragment();

	for (var i = 0; i < data.length; i++) {
		const currentData = data[i];
		if (!currentData) continue;
		if (currentData.type === 'text') {
			fragment.appendChild(document.createTextNode(currentData.data));
		} else {
			var span = document.createElement('span');
			var math = currentData.data; // Override any display mode defined in the settings with that
			// defined by the text itself

			options.displayMode = !!currentData.display;

			try {
				if (options.preProcess) {
					math = options.preProcess(math);
				}

				katex.render(math, span, options);
			} catch (e) {
				if (!(e instanceof katex.ParseError)) {
					throw e;
				}

				options.errorCallback(
					'KaTeX auto-render: Failed to parse `' + currentData.data + '` with ',
					e
				);

				if (currentData.rawData) {
					fragment.appendChild(document.createTextNode(currentData.rawData));
				}
				continue;
			}

			fragment.appendChild(span);
		}
	}

	return fragment;
}

function renderElem(elem: HTMLElement | ChildNode, options: Options) {
	for (var i = 0; i < elem.childNodes.length; i++) {
		var childNode = elem.childNodes[i];

		if (!childNode) continue;

		if (childNode.nodeType === Node.TEXT_NODE) {
			// Text node
			// Concatenate all sibling text nodes.
			// Webkit browsers split very large text nodes into smaller ones,
			// so the delimiters may be split across different nodes.
			var textContentConcat = childNode.textContent;
			if (!textContentConcat) continue;
			var sibling = childNode.nextSibling;
			var nSiblings = 0;

			while (sibling && sibling.nodeType === Node.TEXT_NODE) {
				textContentConcat += sibling.textContent;
				sibling = sibling.nextSibling;
				nSiblings++;
			}

			var frag = renderMathInText(textContentConcat, options);

			if (frag) {
				// Remove extra text nodes
				for (var j = 0; j < nSiblings; j++) {
					const nextSibling = childNode.nextSibling;
					if (!nextSibling) continue;
					nextSibling.remove();
				}

				i += frag.childNodes.length - 1;
				elem.replaceChild(frag, childNode);
			} else {
				// If the concatenated text does not contain math
				// the siblings will not either
				i += nSiblings;
			}
		} else if (childNode.nodeType === Node.ELEMENT_NODE) {
			(function () {
				/*@ts-ignore*/
				var className = ' ' + childNode.className + ' ';
				var shouldRender =
					options.ignoredTags.indexOf(childNode.nodeName.toLowerCase()) === -1 &&
					options.ignoredClasses.every((x) => className.indexOf(' ' + x + ' ') === -1);

				if (shouldRender) {
					renderElem(childNode, options);
				}
			})();
		} // Otherwise, it's something else, and ignore it.
	}
}

const DEFAULT_OPTIONS = {
	delimiters: [
		{
			left: '$$',
			right: '$$',
			display: true,
		},
		{
			left: '\\(',
			right: '\\)',
			display: false,
		},
		{ left: '$', right: '$', display: false },
		{
			left: '\\begin{equation}',
			right: '\\end{equation}',
			display: true,
		},
		{
			left: '\\begin{align}',
			right: '\\end{align}',
			display: true,
		},
		{
			left: '\\begin{alignat}',
			right: '\\end{alignat}',
			display: true,
		},
		{
			left: '\\begin{gather}',
			right: '\\end{gather}',
			display: true,
		},
		{
			left: '\\begin{CD}',
			right: '\\end{CD}',
			display: true,
		},
		{
			left: '\\[',
			right: '\\]',
			display: true,
		},
	],
	ignoredTags: ['script', 'noscript', 'style', 'textarea', 'pre', 'code', 'option'],
	ignoredClasses: [],
	errorCallback: console.error,
	macros: {},
	displayMode: false,
	preProcess: (text: string) => text,
};

export function renderMathInElement(elem: HTMLElement | ChildNode, options?: Options) {
	if (!elem) {
		throw new Error('No element provided to render');
	}

	const optionsMerged = options ? mergeDeep(DEFAULT_OPTIONS, options) : DEFAULT_OPTIONS;

	renderElem(elem, optionsMerged);
}

export function renderMathInHtmlString(htmlString: string, options?: Options) {
	const optionsMerged = options ? mergeDeep(DEFAULT_OPTIONS, options) : DEFAULT_OPTIONS;

	return renderMathInText(htmlString, optionsMerged);
}
