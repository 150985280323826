import { type QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import QuestionAnswerText from '../QuestionAnswer/QuestionAnswerText';

type QuestionSingleLineProps = { textAnswers: Array<QuestionAnswerType>; questionIndex: number };

function QuestionSingleLine({ textAnswers, questionIndex }: QuestionSingleLineProps) {
	return textAnswers.map((answer, answerIndex) => (
		<div className="mt-12" key={answer.id}>
			<QuestionAnswerText answerIndex={answerIndex} answer={answer} questionIndex={questionIndex} />
		</div>
	));
}

export default QuestionSingleLine;
