import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import { Checkbox, Flex } from 'antd';
import { useQuestionGroupStore } from './QuestionGroupStore';
import QuestionAnswerChoice from '../QuestionAnswer/QuestionAnswerChoice';

type QuestionMultipleChoiceProps = {
	answerChoices: QuestionAnswerType[];
	questionId: number;
	questionIndex: number;
};

function QuestionMultipleChoice({ answerChoices, questionId, questionIndex }: QuestionMultipleChoiceProps) {
	const selectedAnswerChoices = useQuestionGroupStore((state) => state.selectedAnswerChoices);
	const setSelectedAnswerChoices = useQuestionGroupStore((state) => state.setSelectedAnswerChoices);
	const isChangingAnswersDisabled = useQuestionGroupStore((state) => state.isChangingAnswersDisabled);
	const areCorrectAnswersBeingShown = useQuestionGroupStore((state) => state.areCorrectAnswersBeingShown);

	const selectedAnswerChoiceIds = selectedAnswerChoices
		.filter((choice) => choice.questionId === questionId)
		.map((choice) => choice.answerId);
	const correctAnswerChoiceIds = answerChoices
		.filter((answer) => answer.is_correct)
		.map((answer) => answer.id);

	return (
		<Checkbox.Group
			defaultValue={areCorrectAnswersBeingShown ? correctAnswerChoiceIds : selectedAnswerChoiceIds}
			value={areCorrectAnswersBeingShown ? correctAnswerChoiceIds : selectedAnswerChoiceIds}
			onChange={(checkedValues) => {
				if (isChangingAnswersDisabled) return;

				const selectedAnswerChoiceOptions = checkedValues.map((value) => ({
					questionId,
					answerId: value,
				}));

				setSelectedAnswerChoices(selectedAnswerChoiceOptions);
			}}>
			<Flex gap={16} vertical>
				{answerChoices.map((answer, index) => (
					<QuestionAnswerChoice
						key={answer.id}
						answer={answer}
						answerIndex={index}
						questionIndex={questionIndex}
					/>
				))}
			</Flex>
		</Checkbox.Group>
	);
}

export default QuestionMultipleChoice;
